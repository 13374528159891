<template>
  <div class="footer">
    <div class="footer-in">
      <div class="in-left">
        <div @click="goRouter('Home')">首页</div>
        <div @click="goRouter('recruitingFirm')">会员招募</div>
        <div @click="goRouter('information')">协会动态</div>
        <div @click="goRouter('solution')">关于我们</div>
        <div @click="goRouter('business')" v-if="windowWidth <= 768">有奖征集</div>

      </div>
      <div class="in-center" v-if="windowWidth >768">
        <div>联系方式</div>
        <p>
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/573040b550c542f89746f3446e45ee0b.png" alt="">
          文经理 13422276741(微信同号)</p>
          <p>
          <img src="https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/36f16fc0eba140afbf825d4c6c66fc60.png" alt="">
          广州市黄埔区科学大道112号绿地中央广场C1栋</p>
      </div>
      <div class="in-right">
        <div class="item">
          <img :src="windowWidth > 768 ? pcLogo : mediaLogo" alt="">
          <div>协会公众号</div>
        </div>
      </div>
    </div>
    <div class="footer-footer">
        <div class="footer-inner">
          <a style="color: #c1c1c1;" href="https://beian.miit.gov.cn">粤ICP备17041355号</a> 广州市广农数链信息科技有限公司<br> 广州市农药肥料应用协会@版权所有
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      windowWidth: 1024,
      mediaLogo: 'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/7aebcc85c81448fcabb513ac854e40ce.png',
      pcLogo: 'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/2bd4c04ccece49ecb261b97958420aed.png'
    }
  },
  mounted () {
    this.windowWidth = window.document.body.clientWidth
    window.addEventListener('resize', this.resizePage)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizePage)
  },
  methods: {
    // 页面大小改变
    resizePage () {
      this.windowWidth = window.document.body.clientWidth
    },
    handleTest () {
      this.$message.warning('文档完善中...')
    },
    handleGo () {
      this.$router.push({
        name: 'recruitAgent'
      })
    },
    goRouter (type) {
      this.$router.push({
        name: type,
        query: { }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  background: #222a3c;
  height: 211px;
  .footer-in {
    width: 1200px;
    height: 152px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 36px;
    display: flex;
    .in-left{
      display: flex;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      line-height: 19px;
      div{
        margin-right: 90px;
        white-space: nowrap;
        cursor: pointer;
      }
    }
    .in-center{
      margin-left: 59px;
      font-size: 16px;
      font-weight: 400;
      p{
        padding-top: 12px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-400;
        line-height: 16px;
        font-weight: 400;
        img{
          width: 26px;
          height: 26px;
        }
      }
    }
    .in-right{
      display: flex;
      flex-direction: column;
      margin-left: 106px;
      img{
        width: 66px;
        height: 66px;
      }
      div{
        padding-top: 3px;
      }
    }
  }
  .footer-footer{
    height: 59px;
    border: 0.5px solid #404756;
    .footer-inner{
      height: 59px;
      line-height: 20px;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: CENTER;
      color: #c1c1c1 !important;
      padding-top: 10px;
    }
  }
}
.active:hover{
  color: #00a0ac;
}
</style>

<style lang='scss' scoped>
// 手机端 样式
@import './mediaStyle/footer';
</style>
